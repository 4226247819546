.bannerimgContainer {
  padding: 20px;
}

.bannerimg {
  object-fit: contain;
  /* max-width: 150vh; */
  animation-name: floating;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

.bannerimg img {
  object-fit: contain;
}

@keyframes floating {
  0% {
    transform: translate(0, 0px);
  }

  50% {
    transform: translate(0, 25px);
  }

  100% {
    transform: translate(0, 0px);
  }
}

/* util */

.grid {
  margin: 0;
  padding: 0;

  grid-gap: 0px !important;
}

.cell {
  margin: 0;
  padding: 0;

  /* border: 1px solid #020c10; */
}

.neonText {
  color: #ff1616;
  /* text-shadow:
    0 0 7px #ff1616,
    0 0 10px #ff1616; */
}

.button {
  margin-left: auto;
  margin-right: auto;

  background-color: transparent !important;

  border-radius: 7px;

  width: 80%;

  /* color: #6d4c44; */
  font-size: 20pt;
}

.button a {
  color: #f34960;
  font-size: 20pt;
}

.introFooter {
  padding: 20px;
  margin-left: 20px;
  width: 100%;
}

.introFooter pre {
  background: none;
  color: white;
  font-weight: lighter;
  font-size: 45pt;
  overflow: hidden;
}

.introFooter span {
  background: none;
  color: white;
  font-weight: normal;
  font-size: 25pt;
}


.arrowLeft {
  border: solid #f34960;
  border-width: 0 20px 20px 0;
  display: inline-block;
  padding: 15px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.arrowRight {
  border: solid #f34960;
  border-width: 0 20px 20px 0;
  display: inline-block;
  padding: 15px;
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.bottom {
  position: fixed;
  bottom: 10vh;
  width: 100%;
}

@media (min-width: 1100px) {
  .intro {
    border: none;

    margin-left: auto;
    margin-right: auto;
    height: 100vh;
  }

  .description {
    max-width: 800px;
    margin: auto;
    color: #020c10;
  }
}

@media (max-width: 1100px) and (min-width: 481px) {
  .bannerimgContainer {
    padding: 0;
    margin-left: 0;
  }

  .bannerimg {
    max-width: 100vw;
  }

  .intro {
    border: none;

    margin-left: auto;
    margin-right: auto;
  }

  .description {
    max-width: 80vw;
    margin: auto;
    color: #020c10;
  }
}

@media (max-width: 481px) {
  .bannerimgContainer {
    padding: 0;
    margin-left: 0;
  }

  .bannerimg {
    max-width: 100vw;
  }

  .intro {
    border: none;

    margin-left: auto;
    margin-right: auto;
  }

  .description {
    max-width: 80vw;
    margin: auto;
    color: #020c10;
  }
}

/* button glow */


.glowOnHover {
  height: 50px;
  border: none;
  outline: none;
  color: #fff;
  background: transparent;
  cursor: pointer;
  position: relative;
  z-index: 0;

  font-size: 25pt;
  text-align: center;
  display: block;
  width: 100%;
}

.glowOnHover:before {
  content: '';
  background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
  position: absolute;
  top: -10px;
  left: -10px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  width: calc(100% + 20px);
  height: calc(100% + 20px);
  animation: glowing 20s linear infinite;
  opacity: 0;
  transition: opacity .3s ease-in-out;
  border-radius: 10px;
}

.glowOnHover:active {
  color: #000
}

.glowOnHover:active:after {
  background: transparent;
}

.glowOnHover:hover:before {
  opacity: 1;
}

.glowOnHover:after {
  z-index: -1;
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  border-radius: 10px;
}

@keyframes glowing {
  0% {
    background-position: 0 0;
  }

  50% {
    background-position: 400% 0;
  }

  100% {
    background-position: 0 0;
  }
}