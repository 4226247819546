:global(.menu-list *):hover {
  background-color: transparent !important;
  font-weight: bolder;
}

:global(.menu-list a a):hover {
  background-color: rgba(255, 255, 255, 0.1) !important;
}

.separator {
  background-color: #030303;
  color: #030303;

  /* background: linear-gradient(to right,
      #f34960 40%,
      #e7b67e 40% 50%,
      #e4e48c 50% 60%,
      #96ee96 60% 70%,
      #8adcff 70% 80%,
      #9595e9 80% 90%,
      #e667aa 90%); */

  background-color: white;

  height: 5px;
  max-width: 600px;
  background-size: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}


/* util */

.grid {
  margin: 0;
  padding: 0;

  grid-gap: 0px !important;
}

.cell {
  margin: 0;
  padding: 0;

  grid-gap: 0px !important;
}

.neonText {
  color: #ff1616;
  text-shadow:
    0 0 2px #ff1616,
    0 0 4px #ff1616;
}

@media (min-width: 1100px) {
  .container {
    max-width: 90vw;

    margin: auto;
  }

  .genius {
    color: #ff1616;
    font-size: 40pt;
    text-align: left;
  }

  .geniusAbout {
    color: white;
    font-size: 20pt;
    text-align: left;
  }

  .logo {
    max-height: 250px;
  }

  .logo img {
    max-height: 250px;
  }

  .intro {
    border: none;

    margin-left: auto;
    margin-right: auto;
  }

  .notionLink {
    padding: 5px !important;
    margin: 0 !important;
    text-align: left;
    color: white !important;
    font-size: 13pt;
    text-decoration: none;
    width: 100%;
    font-weight: bolder;

    /* border-bottom: 2px solid white; */
  }
}

@media (max-width: 1100px) {
  .container {
    max-width: 90vw;

    margin: auto;
  }

  .genius {
    color: #ff1616;
    font-size: 25pt;
    text-align: left;
  }

  .geniusAbout {
    color: white;
    font-size: 16pt;
    text-align: left;
  }

  .logo {
    max-height: 250px;
  }

  .logo img {
    max-height: 250px;
  }

  .intro {
    border: none;

    margin-left: auto;
    margin-right: auto;
  }

  .notionLink {
    padding: 0 !important;
    margin: 0 !important;
    text-align: left;
    color: white !important;
    font-size: 12pt;
    text-decoration: none;
    width: 100%;
    font-weight: bolder;

    /* border-bottom: 2px solid white; */
  }
}