.App {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: auto;

  text-align: center;

  background: linear-gradient(to right,
      #f34960 40%,
      #e7b67e 40% 50%,
      #e4e48c 50% 60%,
      #96ee96 60% 70%,
      #8adcff 70% 80%,
      #9595e9 80% 90%,
      #e667aa 90%);

  background-size: 100% 100%;

  padding: 10px;

  font-size: 10pt;
  overflow-y: auto;
  overflow-x: hidden;
}

body {
  font-family: "Poppins, sans-serif";
}

.appContent {
  border-radius: 30px;
  height: calc(100vh - 10px);
  overflow-y: hidden;
  overflow-x: hidden;
}

@media (min-width: 1100px) {
  .App {
    padding: 0px;
  }

  .appContent {
    border-radius: 0px;
    background-color: black;
  }
}

@media (max-width: 1100px) {
  .App {
    padding: 0px;
  }

  .appContent {
    border-radius: 0px;

    background-color: black;
  }
}

/* starry background */

.stars,
.twinkling,
.clouds {
  width: 100%;
  height: 100%;
  background-color: black;
}

.stars {
  z-index: 0;
  background: #000 url('https://i.imgur.com/YKY28eT.png') repeat top center;
}

.twinkling {
  z-index: 1;
  background: transparent url('https://i.imgur.com/XYMF4ca.png') repeat top center;
  animation: move-twink-back 200s linear infinite;
}

.clouds {
  z-index: 2;
  background: transparent url('https://i.imgur.com/mHbScrQ.png') repeat top center;
  animation: move-clouds-back 200s linear infinite;
}

@keyframes move-twink-back {
  from {
    background-position: 0 0;
  }

  to {
    background-position: -10000px 5000px;
  }
}

@keyframes move-clouds-back {
  from {
    background-position: 0 0;
  }

  to {
    background-position: 10000px 0;
  }
}